<template>
	<div class="flex flex-col no-scrollbar">
		<div class="sticky-header">
			<WemotooHeader />
		</div>

		<div class="flex-3 no-scrollbar">
			<slot />
		</div>
		<div class="items-end">
			<WemotooFooter />
		</div>
	</div>
</template>

<script lang="ts" setup></script>

<style scoped>
.sticky-header {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
